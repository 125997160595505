@import url(https://fonts.googleapis.com/css?family=Fira+Sans&display=swap);
@import url(https://fonts.googleapis.com/css?family=Inconsolata&display=swap);
// Variables
$background-color: white;
$font: 'Inconsolata', monospace;
$font-color: black;
$header-weight: 300;
$description-weight: 500;

body {
  margin: 0;
  padding: 0;
  font-size: 16px;
  font-family: $font;
  //background: $background-color !important;
  color: $font-color;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

img {
  max-width: 80%;
  max-height: 80%;
}

h2 {
  font-family: $font;
}

ul {
  list-style-type: none;
  //text-align: left;
  padding: 0px 20px 0px 20px;
}

li {
  font-size: 20px;
}

p {
  font-size: 20px;
}

select.form-control,
textarea.form-control,
input.form-control {
  font-size: 16px;
}

input[type=file] {
  width: 100%;
}

.svg-inline--fa {
  overflow: visible;
  vertical-align: -0.125em;
  display: inline-flex;
  align-self: center;
  margin-top: 1.5%;
}

.center {
  display: block;
  margin: 0 auto;
  width: 50%;
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-40%);
  transform: translateY(-60%);
}

.row-padding {
  padding-bottom: 25px;
}

.margin-top-25 {
  margin-top: 25px;
}

// App
.App {
  padding-top: 5px;
  padding-bottom: 5px;
  color: $font-color;
  .navbar-brand {
    font-weight: bold;
    font-family: $font;
    font-size: 25px;
  }
  .navbar {
    display: block;
    justify-content: space-between;
    font-family: $font;
  }
  .nav-link {
    display: inline-flex;
    padding: 1rem 1rem;
    font-size: 1.5rem;
    color: $font-color !important;
    display: inline-block;
    color: $font-color;
    letter-spacing: .1em;
    text-decoration: none;
    transition: opacity .3s;

    &:hover {
      opacity: .5;
    }
  }
}

// Form
@media all and (max-width: 991px) {
  .experience-center {
    text-align: center;
    padding-bottom: 15px;
  }
}

// Hero
.hero {
  .lander {
    background: $background-color;
    color: $font-color;
    padding: 8rem 0 2rem;
    text-align: center;
    h1 {
      font-family: $font;
      font-weight: 800;
      font-size: 75px;
      text-transform: uppercase;
    }
    .cta {
      display: block;
      align-items: center;
      justify-content: center;
      text-align: center;
      padding: 0px 50px 0px 50px;
    }
    .left-align {
      text-align: left;
    }
  }
}

// Scrolling Animation
.demo a {
  bottom: 20px;
  display: inline-block;
  transform: translate(0, -50%);
  color: $font-color;
  letter-spacing: .1em;
  text-decoration: none;
  transition: opacity .3s;
  margin-top: 20%;

  &:hover {
    opacity: .5;
  }
}

.section10 a {
  padding-top: 40px;

  span {
    position: absolute;
    top: 0;
    left: 50%;
    width: 30px;
    height: 50px;
    margin-left: -15px;
    border: 3px solid $font-color;
    border-radius: 50px;
    box-sizing: border-box;

    &::before {
      position: absolute;
      top: 10px;
      content: '';
      width: 6px;
      height: 6px;
      margin-left: -3px;
      background-color: $font-color;
      border-radius: 100%;
      -webkit-animation: sdb10 2s infinite;
      animation: sdb10 2s infinite;
      box-sizing: border-box;
    }
  }
}

@-webkit-keyframes sdb10 {
  0% {
    -webkit-transform: translate(0, 0);
    opacity: 0;
  }

  40% {
    opacity: 1;
  }

  80% {
    -webkit-transform: translate(0, 20px);
    opacity: 0;
  }

  100% {
    opacity: 0;
  }
}

@keyframes sdb10 {
  0% {
    transform: translate(0, 0);
    opacity: 0;
  }

  40% {
    opacity: 1;
  }

  80% {
    transform: translate(0, 20px);
    opacity: 0;
  }

  100% {
    opacity: 0;
  }
}